import React from 'react';
import { BsLightbulb } from 'react-icons/all';
import { ImgContainer } from '@app/components/mol.card/module-card.component.styled';
import { StepContainer, BoldParagraph, Paragraph, CentralizedRow, BoldWord, Title } from '../tutorial.styles';
var DecimoStep = function () { return (React.createElement(StepContainer, null,
    React.createElement(BoldParagraph, null, "Nossas funcionalidades"),
    React.createElement(CentralizedRow, null,
        React.createElement(ImgContainer, null,
            React.createElement(BsLightbulb, null)),
        React.createElement(Title, { style: { fontSize: '1rem', marginBottom: '10px', paddingLeft: '10px' } }, "Caminhos do Conhecimento"),
        React.createElement(Paragraph, { style: { marginTop: '5%', marginBottom: '5%' } }, "Aqui voc\u00EA ir\u00E1 visualizar os cursos e treinamentos disponibilizados e lan\u00E7ados pela sua empresa."),
        React.createElement(Paragraph, { style: { marginTop: '5%', marginBottom: '5%' } },
            "Fique atento aos status dos treinamentos, quando voc\u00EA perder o prazo para realiz\u00E1-lo, o statuts muda para",
            ' ',
            React.createElement(BoldWord, null, "Atrasado"),
            ", isso ser\u00E1 sinalizado no card."),
        React.createElement(Paragraph, { style: { marginTop: '5%', marginBottom: '5%' } },
            "Um treinamento pode ser feito aos poucos, quando este for o caso, ele mudar\u00E1 o status para",
            ' ',
            React.createElement(BoldWord, null, "Em andamento"),
            ". E sempre que retornar, voc\u00EA poder\u00E1 continuar de onde parou!")))); };
export default DecimoStep;
