export var NotificationType;
(function (NotificationType) {
    NotificationType["CURSO"] = "Curso";
    NotificationType["QUESTIONARIO"] = "Questionario";
    NotificationType["COMUNICADO"] = "Comunicado";
})(NotificationType || (NotificationType = {}));
export var NotificationFilter;
(function (NotificationFilter) {
    NotificationFilter["ALL"] = "all";
    NotificationFilter["UNREAD"] = "unread";
})(NotificationFilter || (NotificationFilter = {}));
