import React from 'react';
import { BsCardChecklist } from 'react-icons/all';
import { ImgContainer } from '@app/components/mol.card/module-card.component.styled';
import { StepContainer, BoldParagraph, Paragraph, CentralizedRow, BoldWord, Title } from '../tutorial.styles';
var NonoStep = function () { return (React.createElement(StepContainer, null,
    React.createElement(BoldParagraph, { style: { marginTop: '2%', marginBottom: '2%' } }, "Nossas funcionalidades"),
    React.createElement(CentralizedRow, null,
        React.createElement(ImgContainer, null,
            React.createElement(BsCardChecklist, null)),
        React.createElement(Title, { style: { fontSize: '1rem', marginBottom: '10px', paddingLeft: '10px' } }, "Pesquisas"),
        React.createElement(Paragraph, { style: { marginTop: '2%', marginBottom: '2%' } }, "Aqui voc\u00EA ir\u00E1 visualizar as pesquisas lan\u00E7adas pela sua empresa."),
        React.createElement(Paragraph, { style: { marginTop: '2%', marginBottom: '2%' } },
            "As Pesquisas ",
            React.createElement(BoldWord, null, "Novas"),
            " sempre estar\u00E3o no topo para voc\u00EA realiz\u00E1-las assim que poss\u00EDvel."),
        React.createElement(Paragraph, { style: { marginTop: '2%', marginBottom: '2%' } },
            "Fique atento aos status das pesquisas, quando voce perder o prazo para responder a pesquisa ela ter\u00E1 o status de",
            ' ',
            React.createElement(BoldWord, null, "Atrasado"),
            ", isso ser\u00E1 sinalizado no card."),
        React.createElement(Paragraph, { style: { marginTop: '2%', marginBottom: '2%' } },
            "Uma pesquisa pode ser iniciada, mas n\u00E3o precisa ser finalizada no mesmo momento. Nesse caso, ela ter\u00E1 o status de ",
            React.createElement(BoldWord, null, "Em andamento"),
            " e ao retornar, voc\u00EA poder\u00E1 continuar de onde parou!")))); };
export default NonoStep;
