import * as React from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-unassigned-import
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Header } from '@app/components/mol.header';
import { axiosClient } from '@app/configure-app';
import { useStore } from '@app/core/services';
import { useUserStore } from '@app/providers';
import { AppPath } from '@app/routes';
import { Button, StyledContainer } from '@atomic';
import { OuterDiv } from '../questionnarie/questionnaire.styled';
import DecimoPrimeiroStep from './tutorial-steps/decimo-primeiro-step';
import DecimoSegundoStep from './tutorial-steps/decimo-segundo-step';
import DecimoStep from './tutorial-steps/decimo-step';
import NonoStep from './tutorial-steps/nono-step';
import OitavoStep from './tutorial-steps/oitavo-step';
import PrimeiroStep from './tutorial-steps/primeiro-step';
import QuartoStep from './tutorial-steps/quarto-step';
import QuintoStep from './tutorial-steps/quinto-step';
import SegundoStep from './tutorial-steps/segundo-step';
import SetimoStep from './tutorial-steps/setimo-step';
import SextoStep from './tutorial-steps/sexto-step';
import TerceiroStep from './tutorial-steps/terceiro-step';
import { Container, Content, Dot, DotsContainer, LeftSide, Navigation, RightSide, } from './tutorial.styles';
export var TutorialPage = function (props) {
    var _a, _b;
    var history = useHistory();
    var withBackButton = !!((_b = (_a = props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.withBackButton);
    var userData = useStore(useUserStore).userData;
    var _c = React.useState(''), urlVideo = _c[0], setUrlVideo = _c[1];
    var saveIsTutorialReadedLSToken = function () {
        localStorage.setItem('is-tutorial-readed', "is-tutorial-readed-" + userData.usuarioId);
    };
    var handleOnConfirmClick = function () {
        if (withBackButton) {
            history.goBack();
        }
        else {
            saveIsTutorialReadedLSToken();
            history.push(AppPath.ContagemRegressiva);
        }
    };
    var fetchConfigs = function () {
        axiosClient.get('configuracao/key/UrlVideoTutorial').then(function (response) {
            setUrlVideo(response.data.data.value);
        });
    };
    React.useEffect(function () {
        fetchConfigs();
    }, []);
    var Footer = function () { return (React.createElement(StyledContainer, { justifyEnd: true, alignCenter: true },
        React.createElement(Button, { expanded: true, onClick: handleOnConfirmClick }, "ENTENDI"))); };
    var steps = [
        React.createElement(PrimeiroStep, { key: 1 }),
        React.createElement(SegundoStep, { key: 2 }),
        React.createElement(TerceiroStep, { key: 3 }),
        React.createElement(QuartoStep, { key: 4 }),
        React.createElement(QuintoStep, { key: 5 }),
        React.createElement(SextoStep, { key: 6 }),
        React.createElement(SetimoStep, { key: 7 }),
        React.createElement(OitavoStep, { key: 8 }),
        React.createElement(NonoStep, { key: 9 }),
        React.createElement(DecimoStep, { key: 10 }),
        React.createElement(DecimoPrimeiroStep, { key: 11 }),
        React.createElement(DecimoSegundoStep, { key: 12 }),
    ];
    var _d = React.useState(0), currentStep = _d[0], setCurrentStep = _d[1];
    var handleNext = function () {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };
    var handleFinish = function () {
        return history.push({ pathname: AppPath.Map });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { primaryColor: true, className: 'use-inverse-colors' },
            React.createElement(Header, { withLogoutButton: false }),
            React.createElement("br", null)),
        React.createElement(OuterDiv, null,
            React.createElement(Container, null,
                React.createElement(Content, null, steps[currentStep]))),
        React.createElement(Navigation, null,
            React.createElement(LeftSide, null),
            React.createElement(DotsContainer, null, steps.map(function (_, index) { return (React.createElement(Dot, { key: index, isActive: index === currentStep })); })),
            React.createElement(RightSide, null, currentStep === steps.length - 1 ? (React.createElement(Button, { onClick: handleFinish }, "Concluir")) : (React.createElement(Button, { onClick: handleNext }, "Seguir"))))));
};
