var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Col } from '@atomic';
import { StepContainer, BoldParagraph, Paragraph } from '../tutorial.styles';
var CenteredCol = styled(Col)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 20%;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  margin-top: 20%;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n"])));
var DecimoSegundoStep = function () { return (React.createElement(StepContainer, null,
    React.createElement(CenteredCol, null,
        React.createElement(BoldParagraph, null, "Esperamos que voc\u00EA tenha uma excelente experi\u00EAncia no nosso app."),
        React.createElement(Paragraph, null, "Nossa fun\u00E7\u00E3o \u00E9 facilitar o seu dia a dia.")))); };
// You can add more step components here following the same pattern...
export default DecimoSegundoStep;
var templateObject_1;
