import React from 'react';
import { Apps } from '@mui/icons-material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { BsHouseDoor } from 'react-icons/bs';
import { HomeOption } from '@app/modules/map/map.page.style';
import { StepContainer, BoldParagraph, Paragraph, CentralizedRow } from '../tutorial.styles';
var TerceiroStep = function () { return (React.createElement(StepContainer, null,
    React.createElement(BoldParagraph, null, "Como navegar na aplica\u00E7\u00E3o"),
    React.createElement(Paragraph, null, "Voc\u00EA encontrar\u00E1 esses quarto bot\u00F5es:"),
    React.createElement(CentralizedRow, null,
        React.createElement("div", { style: { marginRight: '40px' } },
            React.createElement(HomeOption, null,
                React.createElement(BsHouseDoor, { fontSize: '1.8rem', color: "white" }))),
        React.createElement(HomeOption, null,
            React.createElement(NotificationsNoneIcon, { sx: { fill: '#fff', fontSize: '1.8rem' } })),
        React.createElement(HomeOption, null,
            React.createElement(PersonOutlineOutlinedIcon, { sx: { fill: '#fff', fontSize: '1.8rem' } })),
        React.createElement(HomeOption, null,
            React.createElement(Apps, { style: { color: 'white', fontSize: '23px' } }))),
    React.createElement(BoldParagraph, null, "Vamos explic\u00E1-los individualmente."),
    React.createElement(CentralizedRow, null,
        React.createElement(HomeOption, null,
            React.createElement(BsHouseDoor, { fontSize: '1.8rem', color: "white" }))),
    React.createElement(Paragraph, null, "Esse bot\u00E3o sempre estar\u00E1 presente no final da tela, o que possibilita que voc\u00EA consegue seguir para a P\u00E1gina Inicial do App sempre que necess\u00E1rio."))); };
export default TerceiroStep;
