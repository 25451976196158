var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var BacksideContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  grid-template-columns: 3fr 0.3fr;\n  border: 1px solid ", ";\n  background-color: ", ";\n  max-height: fit-content;\n  min-height: 60px;\n  padding: 12px;\n  padding-bottom: 0px;\n  cursor: pointer;\n"], ["\n  grid-template-columns: 3fr 0.3fr;\n  border: 1px solid ", ";\n  background-color: ", ";\n  max-height: fit-content;\n  min-height: 60px;\n  padding: 12px;\n  padding-bottom: 0px;\n  cursor: pointer;\n"])), function (props) { return props.borderColor; }, function (props) { return props.backgroundColor; });
export var NotificationCardContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-auto-rows: auto;\n  gap: 8px;\n"], ["\n  display: grid;\n  grid-auto-rows: auto;\n  gap: 8px;\n"])));
export var FlexColSimple = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding-left: 4px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding-left: 4px;\n"])));
export var FlexRowSimple = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  column-gap: 15px;\n  padding-left: 15px;\n  padding-right: 15px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  column-gap: 15px;\n  padding-left: 15px;\n  padding-right: 15px;\n"])));
export var TitlePageText = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: inline-block;\n  font-weight: bold;\n  font-size: 2rem;\n  color: '#49443C';\n"], ["\n  display: inline-block;\n  font-weight: bold;\n  font-size: 2rem;\n  color: '#49443C';\n"])));
export var TitleCardText = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: inline-block;\n  font-weight: bold;\n  font-size: 1rem;\n  margin-right: 5px;\n  color: '#49443C';\n"], ["\n  display: inline-block;\n  font-weight: bold;\n  font-size: 1rem;\n  margin-right: 5px;\n  color: '#49443C';\n"])));
export var SubtitleCardText = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: inline-block;\n  font-weight: bold;\n  font-size: 1.2rem;\n  text-transform: uppercase;\n  color: '#49443C';\n"], ["\n  display: inline-block;\n  font-weight: bold;\n  font-size: 1.2rem;\n  text-transform: uppercase;\n  color: '#49443C';\n"])));
export var DatetimeCardText = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: inline-block;\n  font-weight: bold;\n  font-size: 0.7rem;\n  text-transform: uppercase;\n  color: #3f3f3f;\n"], ["\n  display: inline-block;\n  font-weight: bold;\n  font-size: 0.7rem;\n  text-transform: uppercase;\n  color: #3f3f3f;\n"])));
export var CardStatus = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 120px;\n  height: 22px;\n  max-width: 120px;\n  max-height: 22px;\n  text-align: center;\n  border-top-right-radius: 16px;\n  border-top-left-radius: 16px;\n  margin: 0 auto;\n  position: relative;\n  background-color: ", ";\n"], ["\n  width: 120px;\n  height: 22px;\n  max-width: 120px;\n  max-height: 22px;\n  text-align: center;\n  border-top-right-radius: 16px;\n  border-top-left-radius: 16px;\n  margin: 0 auto;\n  position: relative;\n  background-color: ", ";\n"])), function (props) { return props.cardColor; });
export var CardStatusText = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: inline-block;\n  font-size: 14px;\n  width: 120px;\n  height: 22px;\n  max-width: 120px;\n  max-height: 22px;\n  text-align: center;\n  color: #ffffff;\n"], ["\n  display: inline-block;\n  font-size: 14px;\n  width: 120px;\n  height: 22px;\n  max-width: 120px;\n  max-height: 22px;\n  text-align: center;\n  color: #ffffff;\n"])));
export var ApagarButton = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  border-radius: 8px;\n  background-color: ", ";\n  border: none;\n  color: #49443c;\n  max-width: 100px;\n  display: flex;\n  column-gap: 2px;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  cursor: pointer;\n\n  svg {\n    color: #49443c;\n    fill: #49443c;\n    color-fill: #49443c;\n    width: 20px;\n    height: 20px;\n    margin: auto;\n  }\n\n  span {\n    color: #49443c;\n  }\n"], ["\n  border-radius: 8px;\n  background-color: ", ";\n  border: none;\n  color: #49443c;\n  max-width: 100px;\n  display: flex;\n  column-gap: 2px;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  cursor: pointer;\n\n  svg {\n    color: #49443c;\n    fill: #49443c;\n    color-fill: #49443c;\n    width: 20px;\n    height: 20px;\n    margin: auto;\n  }\n\n  span {\n    color: #49443c;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.backgroundColor;
});
export var LoadMoreInfo = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  text-align: center;\n  text-decoration: underline;\n  cursor: pointer;\n  margin-top: 10px;\n  color: ", ";\n\n  font-size: 0.9rem;\n  font-weight: bold;\n"], ["\n  text-align: center;\n  text-decoration: underline;\n  cursor: pointer;\n  margin-top: 10px;\n  color: ", ";\n\n  font-size: 0.9rem;\n  font-weight: bold;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
