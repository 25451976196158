import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { CloseIconStyled } from '@app/modules/track/modal/modal.component.style';
import { Button } from '@atomic';
var styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};
export var ModalLimparNotificacoes = function (_a) {
    var open = _a.open, onClose = _a.onClose;
    var _b = React.useState('ALL'), value = _b[0], setValue = _b[1];
    var handleChange = function (event) {
        setValue(event.target.value);
    };
    return (React.createElement(Modal, { open: open, onClose: onClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
        React.createElement(Box, { sx: styleModal },
            React.createElement("div", { style: { display: 'flex', justifyContent: 'flex-end' } },
                React.createElement(CloseIconStyled, { onClick: function () { return onClose(undefined); } })),
            React.createElement(FormControl, { style: { display: 'flex' } },
                React.createElement(FormLabel, { id: "demo-radio-buttons-group-label", style: { textAlign: 'justify' } }, "Limpar Notifica\u00E7\u00F5es"),
                React.createElement(RadioGroup, { "aria-labelledby": "demo-radio-buttons-group-label", defaultValue: "ALL", name: "radio-buttons-group", value: value, onChange: handleChange },
                    React.createElement(FormControlLabel, { value: "ALL", control: React.createElement(Radio, null), label: "Todas as Notifica\u00E7\u00F5es" }),
                    React.createElement(FormControlLabel, { value: "READ", control: React.createElement(Radio, null), label: "Notifica\u00E7\u00F5es Lidas" })),
                React.createElement(Button, { onClick: function () { return onClose(value); }, style: { textAlign: 'center' } }, "Limpar")))));
};
