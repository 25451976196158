import React from 'react';
import { FiCheckCircle } from 'react-icons/all';
import { ImgContainer } from '@app/components/mol.card/module-card.component.styled';
import { StepContainer, BoldParagraph, Paragraph, CentralizedRow, Title } from '../tutorial.styles';
var OitavoStep = function () { return (React.createElement(StepContainer, null,
    React.createElement(BoldParagraph, null, "Nossas funcionalidades"),
    React.createElement(CentralizedRow, null,
        React.createElement(ImgContainer, null,
            React.createElement(FiCheckCircle, null)),
        React.createElement(Title, { style: { fontSize: '1rem', marginBottom: '10px', paddingLeft: '10px' } }, "Checklist"),
        React.createElement(Paragraph, null, "Aqui voc\u00EA ir\u00E1 visualizar tarefas necess\u00E1rias para fazer no seu dia atrav\u00E9s de checklists."),
        React.createElement(Paragraph, null, "Al\u00E9m dos checklists do dia, aqui voc\u00EA tamb\u00E9m ter\u00E1 aba de \u201Chist\u00F3rico\u201D onde ter\u00E1 acesso aos checklists realizados e tamb\u00E9m ter\u00E1 acesso aos que j\u00E1 est\u00E3o agendados em seu nome na aba de \u201CPr\u00F3ximos\u201D.")))); };
export default OitavoStep;
