import { AuthPath } from '@app/modules/authentication/auth-path';
export var AppPath = {
    Root: '/',
    Map: '/menu',
    ContagemRegressiva: '/contagem',
    Configurations: '/configurations',
    PendingChecklist: '/checklists/pending/:id',
    PendingUnitChecklist: '/checklists/unit/pending/:id/:uniEmpId',
    ChecklistDetails: '/checklists/:id',
    ChecklistUnidadeDetails: '/checklists-unidades/:id',
    RoutineChecklist: '/checklist-de-rotina',
    LearningValidation: '/validacao-de-aprendizado',
    ChecklistFilters: '/checklists/filters',
    NewChecklist: '/checklists/new',
    NewUnitChecklist: '/checklists/unit/new',
    Track: '/track/:courseCode',
    Store: '/store',
    Help: '/ajuda',
    Login: AuthPath.Login,
    LoginWithCPF: AuthPath.LoginWithCPF,
    ExternalLogin: AuthPath.ExternalLogin,
    Optout: '/optout/usuario/:id',
    RegisterFinish: AuthPath.RegisterFinish,
    PhoneRegister: AuthPath.PhoneRegister,
    PersonalDataUpdate: AuthPath.PersonalDataUpdate,
    SignUp: AuthPath.SignUp,
    Quiz: '/quiz',
    Question: '/question',
    QuizComunicado: '/quiz-comunicado',
    ComunicadoCompleted: '/comunicado-completed',
    Behaviour: '/behaviour',
    QuizWin: '/quiz-win',
    QuizResult: '/resultado-quiz',
    PhaseCompleted: '/phase-completed',
    CourseCompleted: '/course-completed',
    QuestionnaireCompleted: '/questionnaire-completed',
    PlayerProfile: '/player-profile',
    Notifications: '/notifications',
    Learning: '/quero-aprender',
    FirstAccess: AuthPath.FirstAccess,
    Ranking: '/ranking',
    ChangePassword: AuthPath.ChangePassword,
    RecoverPassword: AuthPath.RecoverPassword,
    NewPassword: AuthPath.NewPassword,
    UseTerms: '/termos-de-uso',
    Tutorial: '/tutorial',
    Library: '/library',
    Chamados: '/chamados',
    NovoChamado: '/chamados/novo',
    ReportError: '/report-error',
    ValidacaoDados: '/validacao-dados',
    Research: '/pesquisa',
    ResearchCategory: '/pesquisa/categoria/:id',
    UnitCourse: '/trilha-unidade',
    UnitCourseCategory: '/trilha-unidade/categoria/:id',
    GroupCourse: '/trilha-de-aprendizado',
    GroupCourseCategory: '/trilha-de-aprendizado/categoria/:id',
    Communicate: '/comunicados',
    PendingApproval: '/pendente-de-aprovacao/:id',
    DeniedChecklist: '/checklists-recusados/:id',
    ExternalQuestion: '/pesquisa-ext',
    Nps: '/nps',
};
