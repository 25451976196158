import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RecoverPasswordPage } from '@app/modules/authentication';
import { PhoneRegisterPage } from '@app/modules/authentication/external/phone-register.page';
import { RegisterFinishPage } from '@app/modules/authentication/external/register-finish.page';
import { FirstAccessWithCPFPage } from '@app/modules/authentication/first-access-with-cpf.page';
import { FirstAccessPage } from '@app/modules/authentication/first-access.page';
import { NewPasswordPage } from '@app/modules/authentication/new-password.page';
import { PersonalDataUpdatePage } from '@app/modules/authentication/personal-data-update.page';
import SplashScreen from '@app/modules/authentication/splash-screen';
import { ValidacaoDadosPage } from '@app/modules/authentication/validacao-dados.page';
import { BehavioralQuestionsPage } from '@app/modules/behavioral-questions/behavioral-questions.page';
import { ChamadosPage } from '@app/modules/chamados';
import { ChecklistPage } from '@app/modules/checklist';
import { ChecklistDetailsPage } from '@app/modules/checklist/details.page';
import { NewChecklistPage } from '@app/modules/checklist/new-checklist.page';
import { PendingChecklistPage } from '@app/modules/checklist/pending-checklist.page';
import { ComunicadoCompletedPage } from '@app/modules/comunicado/quiz-comunicado-completed.page';
import { QuizComunicadoPage } from '@app/modules/comunicado/quiz-comunicado.page';
import { ConfigurationsPage } from '@app/modules/configuration';
import { CourseLibraryPage } from '@app/modules/course-library';
import { HelpPage } from '@app/modules/help';
import { LearningPage } from '@app/modules/learning/learning.page';
import MapPage from '@app/modules/map';
import Counter from '@app/modules/map/start-counter';
import { NpsPage } from '@app/modules/nps';
import { OptoutPage } from '@app/modules/optout/optout.page';
import { NotificationsPage } from '@app/modules/player/notifications/notifications.page';
import { PlayerProfile } from '@app/modules/player/player-profile/player-profile.page';
import { ExternalQuestionnaire } from '@app/modules/questionnarie/external/external-questionnarie';
import { QuestionnaireCompletedPage } from '@app/modules/questionnarie/questionnaire-completed.page';
import { NewQuestionnaire } from '@app/modules/questionnarie/questionnaire-new';
import { CourseCompletedPage } from '@app/modules/quiz/pages/course-completed.page';
import { PhaseCompletedPage } from '@app/modules/quiz/pages/phase-completed.page';
import { QuizResultPage } from '@app/modules/quiz/pages/quiz-result.page';
import { QuizWin } from '@app/modules/quiz/pages/quiz-win.page';
import { QuizPage } from '@app/modules/quiz/pages/quiz.page';
import { RankingPage } from '@app/modules/ranking';
import { ReportErrorPage } from '@app/modules/report-error';
import { RootPage } from '@app/modules/root-page';
import { ChecklistUnidadeDetailsPage } from '@app/modules/routine-checklist/details.page';
import { LearningValidationPage } from '@app/modules/routine-checklist/learning-validation.page';
import { NewUnitChecklistPage } from '@app/modules/routine-checklist/new-unit-checklist.page';
import { PendingApprovalUnitChecklistPage } from '@app/modules/routine-checklist/pending-approval-checklist.page';
import { PendingUnitChecklistPage } from '@app/modules/routine-checklist/pending-unit-checklist.page';
import { RoutineChecklistFiltersPage } from '@app/modules/routine-checklist/routine-checklists-filters.page';
import { Store } from '@app/modules/store';
import { Communicates } from '@app/modules/submenus/communicates';
import { KnowledgePath } from '@app/modules/submenus/knowledge-path';
import { KnowledgePathCategory } from '@app/modules/submenus/knowledge-path/knowledge-path-category';
import { Researches } from '@app/modules/submenus/researches';
import { ResearchesCategory } from '@app/modules/submenus/researches/researches-category';
import { UnityTrack } from '@app/modules/submenus/unity-track';
import { UnityTrackCategory } from '@app/modules/submenus/unity-track/unity-track-category';
import { TrackPage } from '@app/modules/track/track.page';
import { TutorialPage } from '@app/modules/tutorial';
import { UseTermsPage } from '@app/modules/use-terms';
import { AppPath } from '@app/routes/app-path';
import { DeniedChecklistPage } from '../modules/routine-checklist/denied-checklist/denied-checklist.page';
import { GuardedRoute } from './guarded-route';
export var Routes = function () { return (React.createElement(Switch, null,
    React.createElement(GuardedRoute, { path: AppPath.Configurations, exact: true, component: ConfigurationsPage }),
    React.createElement(GuardedRoute, { path: AppPath.Root, exact: true, component: RootPage }),
    React.createElement(GuardedRoute, { path: AppPath.Map, exact: true, component: MapPage }),
    React.createElement(GuardedRoute, { path: AppPath.Quiz, exact: true, component: QuizPage }),
    React.createElement(GuardedRoute, { path: AppPath.Store, exact: true, component: Store }),
    React.createElement(GuardedRoute, { path: AppPath.QuizWin, exact: true, component: QuizWin }),
    React.createElement(GuardedRoute, { path: AppPath.Question + "/:id", exact: true, component: NewQuestionnaire }),
    React.createElement(GuardedRoute, { path: AppPath.QuizComunicado + "/:id", exact: true, component: QuizComunicadoPage }),
    React.createElement(GuardedRoute, { path: AppPath.Behaviour, exact: true, component: BehavioralQuestionsPage }),
    React.createElement(GuardedRoute, { path: AppPath.QuizResult, exact: true, component: QuizResultPage }),
    React.createElement(GuardedRoute, { path: AppPath.PhaseCompleted, exact: true, component: PhaseCompletedPage }),
    React.createElement(GuardedRoute, { path: AppPath.CourseCompleted, exact: true, component: CourseCompletedPage }),
    React.createElement(GuardedRoute, { path: AppPath.QuestionnaireCompleted, exact: true, component: QuestionnaireCompletedPage }),
    React.createElement(GuardedRoute, { path: AppPath.ComunicadoCompleted, exact: true, component: ComunicadoCompletedPage }),
    React.createElement(GuardedRoute, { path: AppPath.PlayerProfile, exact: true, component: PlayerProfile }),
    React.createElement(GuardedRoute, { path: AppPath.Notifications, exact: true, component: NotificationsPage }),
    React.createElement(GuardedRoute, { path: AppPath.Track, exact: true, component: TrackPage }),
    React.createElement(GuardedRoute, { path: AppPath.Learning, exact: true, component: LearningPage }),
    React.createElement(GuardedRoute, { path: AppPath.Ranking, exact: true, component: RankingPage }),
    React.createElement(GuardedRoute, { path: AppPath.Tutorial, exact: true, component: TutorialPage }),
    React.createElement(GuardedRoute, { path: AppPath.NewChecklist, exact: true, component: NewChecklistPage }),
    React.createElement(GuardedRoute, { path: AppPath.NewUnitChecklist, exact: true, component: NewUnitChecklistPage }),
    React.createElement(GuardedRoute, { path: AppPath.PendingUnitChecklist, exact: true, component: PendingUnitChecklistPage }),
    React.createElement(GuardedRoute, { path: AppPath.PendingApproval, exact: true, component: PendingApprovalUnitChecklistPage }),
    React.createElement(GuardedRoute, { path: AppPath.ChecklistFilters, exact: true, component: RoutineChecklistFiltersPage }),
    React.createElement(GuardedRoute, { path: AppPath.ChecklistDetails, exact: true, component: ChecklistDetailsPage }),
    React.createElement(GuardedRoute, { path: AppPath.ChecklistUnidadeDetails, exact: true, component: ChecklistUnidadeDetailsPage }),
    React.createElement(GuardedRoute, { path: AppPath.PendingChecklist, exact: true, component: PendingChecklistPage }),
    React.createElement(GuardedRoute, { path: AppPath.DeniedChecklist, exact: true, component: DeniedChecklistPage }),
    React.createElement(GuardedRoute, { path: AppPath.RoutineChecklist, exact: true, component: ChecklistPage }),
    React.createElement(GuardedRoute, { path: AppPath.LearningValidation, exact: true, component: LearningValidationPage }),
    React.createElement(GuardedRoute, { path: AppPath.Library, exact: true, component: CourseLibraryPage }),
    React.createElement(GuardedRoute, { path: AppPath.Chamados, exact: true, component: ChamadosPage }),
    React.createElement(GuardedRoute, { path: AppPath.Help, exact: true, component: HelpPage }),
    React.createElement(GuardedRoute, { path: AppPath.RegisterFinish, exact: true, component: RegisterFinishPage }),
    React.createElement(GuardedRoute, { path: AppPath.PersonalDataUpdate, exact: true, component: PersonalDataUpdatePage }),
    React.createElement(GuardedRoute, { path: AppPath.Research, exact: true, component: Researches }),
    React.createElement(GuardedRoute, { path: AppPath.ResearchCategory, exact: true, component: ResearchesCategory }),
    React.createElement(GuardedRoute, { path: AppPath.UnitCourse, exact: true, component: UnityTrack }),
    React.createElement(GuardedRoute, { path: AppPath.UnitCourseCategory, exact: true, component: UnityTrackCategory }),
    React.createElement(GuardedRoute, { path: AppPath.GroupCourse, exact: true, component: KnowledgePath }),
    React.createElement(GuardedRoute, { path: AppPath.GroupCourseCategory, exact: true, component: KnowledgePathCategory }),
    React.createElement(GuardedRoute, { path: AppPath.Communicate, exact: true, component: Communicates }),
    React.createElement(Route, { path: AppPath.Login, exact: true, component: SplashScreen }),
    React.createElement(Route, { path: AppPath.ContagemRegressiva, exact: true, component: Counter }),
    React.createElement(Route, { path: AppPath.LoginWithCPF, exact: true, component: FirstAccessWithCPFPage }),
    React.createElement(Route, { path: AppPath.ExternalLogin, exact: true, component: PhoneRegisterPage }),
    React.createElement(Route, { path: AppPath.Optout, exact: true, component: OptoutPage }),
    React.createElement(Route, { path: AppPath.PhoneRegister, exact: true, component: PhoneRegisterPage }),
    React.createElement(Route, { path: AppPath.UseTerms, exact: true, component: UseTermsPage }),
    React.createElement(Route, { path: AppPath.FirstAccess, exact: true, component: FirstAccessPage }),
    React.createElement(Route, { path: AppPath.RecoverPassword, exact: true, component: RecoverPasswordPage }),
    React.createElement(Route, { path: AppPath.ValidacaoDados, exact: true, component: ValidacaoDadosPage }),
    React.createElement(Route, { path: AppPath.NewPassword, exact: true, component: NewPasswordPage }),
    React.createElement(Route, { path: AppPath.Nps + "/:id", exact: true, component: NpsPage }),
    React.createElement(Route, { path: AppPath.ReportError, exact: true, component: ReportErrorPage }),
    React.createElement(Route, { path: AppPath.ExternalQuestion + "/:id", exact: true, component: ExternalQuestionnaire }))); };
