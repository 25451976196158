var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { ButtonNext, ButtonBack } from 'pure-react-carousel';
import styled from 'styled-components';
import { Row } from '@atomic';
export var ButtonNextSlide = styled(ButtonNext)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 20px;\n  font-weight: bold;\n  cursor: pointer;\n  background: none;\n  border: none;\n  float: right;\n  padding-right: 15px;\n  svg {\n    path {\n      color: ", ";\n    }\n  }\n"], ["\n  color: ", ";\n  font-size: 20px;\n  font-weight: bold;\n  cursor: pointer;\n  background: none;\n  border: none;\n  float: right;\n  padding-right: 15px;\n  svg {\n    path {\n      color: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var ButtonBackSlide = styled(ButtonBack)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  float: left;\n  cursor: pointer;\n  background: none;\n  border: none;\n  color: ", ";\n  font-size: 20px;\n\n  svg {\n    path {\n      color: ", ";\n      font-size: 22px;\n    }\n  }\n"], ["\n  float: left;\n  cursor: pointer;\n  background: none;\n  border: none;\n  color: ", ";\n  font-size: 20px;\n\n  svg {\n    path {\n      color: ", ";\n      font-size: 22px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
}, function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var BoxInterregocao = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  right: 6px;\n  bottom: 45px;\n"], ["\n  position: absolute;\n  right: 6px;\n  bottom: 45px;\n"])));
export var ImagemInterrogacao = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  position: relative;\n  top: -45px;\n  right: 8px;\n"], ["\n  background-color: ", ";\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  position: relative;\n  top: -45px;\n  right: 8px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var TutorialContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  h2 {\n    margin: 0;\n    padding-top: 20px;\n    text-align: center;\n  }\n  h2,\n  h3,\n  h4 {\n    font-weight: bold;\n    color: ", ";\n  }\n"], ["\n  h2 {\n    margin: 0;\n    padding-top: 20px;\n    text-align: center;\n  }\n  h2,\n  h3,\n  h4 {\n    font-weight: bold;\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var VideoPlayerWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 1rem 0.4rem;\n"], ["\n  padding: 1rem 0.4rem;\n"])));
export var Container = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  height: 70vh; /* Full viewport height */\n  width: 90%;\n  margin: 0 auto;\n  padding: 2rem 1rem;\n  box-sizing: border-box;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  height: 70vh; /* Full viewport height */\n  width: 90%;\n  margin: 0 auto;\n  padding: 2rem 1rem;\n  box-sizing: border-box;\n"])));
export var Content = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n"])));
export var Title = styled.h1(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 2rem;\n  font-weight: bold;\n  margin-bottom: 1rem;\n"], ["\n  font-size: 2rem;\n  font-weight: bold;\n  margin-bottom: 1rem;\n"])));
export var Description = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 1.2rem;\n  line-height: 1.5;\n  color: #333;\n"], ["\n  font-size: 1.2rem;\n  line-height: 1.5;\n  color: #333;\n"])));
export var Navigation = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 95%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: -10%;\n"], ["\n  width: 95%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: -10%;\n"])));
export var LeftSide = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  flex: 1; /* Takes up remaining space on the left side */\n"], ["\n  flex: 1; /* Takes up remaining space on the left side */\n"])));
export var RightSide = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  flex: 1; /* Takes up remaining space on the right side */\n  display: flex;\n  justify-content: flex-end; /* Aligns the button to the right */\n"], ["\n  flex: 1; /* Takes up remaining space on the right side */\n  display: flex;\n  justify-content: flex-end; /* Aligns the button to the right */\n"])));
export var Button2 = styled.button(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  padding: 0.75rem 1.5rem;\n  font-size: 1rem;\n  border: none;\n  border-radius: 5px;\n  background-color: #28a745; /* Green color */\n  color: white;\n  cursor: pointer;\n  transition: background-color 0.3s;\n\n  &:hover {\n    background-color: #218838;\n  }\n\n  &:disabled {\n    background-color: #cccccc;\n    cursor: not-allowed;\n  }\n"], ["\n  padding: 0.75rem 1.5rem;\n  font-size: 1rem;\n  border: none;\n  border-radius: 5px;\n  background-color: #28a745; /* Green color */\n  color: white;\n  cursor: pointer;\n  transition: background-color 0.3s;\n\n  &:hover {\n    background-color: #218838;\n  }\n\n  &:disabled {\n    background-color: #cccccc;\n    cursor: not-allowed;\n  }\n"])));
export var DotsContainer = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 0 auto;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 0 auto;\n"])));
export var Dot = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  width: 10px;\n  height: 10px;\n  margin: 0 5px;\n  border-radius: 50%;\n  background-color: ", ";\n"], ["\n  width: 10px;\n  height: 10px;\n  margin: 0 5px;\n  border-radius: 50%;\n  background-color: ", ";\n"])), function (props) { return (props.isActive ? '#007bff' : '#cccccc'); });
export var StepContainer = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  font-size: 1.5rem;\n"], ["\n  font-size: 1.5rem;\n"])));
export var BoldText = styled.p(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  font-weight: 900;\n"], ["\n  font-weight: 900;\n"])));
export var Paragraph = styled.p(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  margin-bottom: 10%;\n  margin-top: 15%;\n"], ["\n  margin-bottom: 10%;\n  margin-top: 15%;\n"])));
export var BoldParagraph = styled(Paragraph)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  font-weight: 900;\n"], ["\n  font-weight: 900;\n"])));
export var CentralizedRow = styled(Row)(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center; /* Centers items horizontally */\n  align-items: center; /* Centers items vertically */\n"], ["\n  display: flex;\n  justify-content: center; /* Centers items horizontally */\n  align-items: center; /* Centers items vertically */\n"])));
export var BoldWord = styled.span(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  font-weight: bold;\n"], ["\n  font-weight: bold;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22;
