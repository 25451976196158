var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useStore } from 'react-context-hook';
import { BsFillPatchQuestionFill } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import useSound from 'use-sound';
import { NewQuestionBodyComponent } from '@app/components/atm.question-component-new/new-question-body.component';
import { Textarea } from '@app/components/atm.question-component-new/new-question-body.style';
import HTMLDisplayToQuestion from '@app/components/htmlToText';
import { Header } from '@app/components/mol.header';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { AppPath } from '@app/routes';
import handMessageSrc from '@assets/img/hand-message.png';
import { Button, Color, FaIcon, FlashMessageEnum, FontFamily, H4, Separator, StyledContainer } from '@atomic';
import { EntHeaderWrapper } from '../learning/learning.page.style';
import { randomMessage } from '../quiz/components/footer/response-footer.component';
import { Footer } from './components/footer/footer.component';
import { AnswerResponseWrapper } from './quiz-comunicado.page.style';
// eslint-disable-next-line @typescript-eslint/no-var-requires
var rightAnswerSound = require('@assets/sounds/right-answer-sound.mp3');
// eslint-disable-next-line @typescript-eslint/no-var-requires
var wrongAnswerSound = require('@assets/sounds/wrong-answer-sound.mp3');
var COMUNICADO_URL = '/comunicado-empresa';
export var QuizComunicadoPage = function () {
    var _a, _b;
    var id = useParams().id;
    var theme = useTheme();
    var company = useStore('company')[0];
    var isMute = useStore('mute')[0];
    var playRightAnswerSound = useSound(rightAnswerSound)[0];
    var playWrongAnswerSound = useSound(wrongAnswerSound, { volume: 0.5 })[0];
    var _c = React.useState(0), currentQuestionIndex = _c[0], setCurrentQuestionIndex = _c[1];
    var _d = React.useState(), currentQuestion = _d[0], setCurrentQuestion = _d[1];
    var _e = React.useState(0), currentAlternativeId = _e[0], setCurrentAlternativeId = _e[1];
    var _f = React.useState(), quiz = _f[0], setQuiz = _f[1];
    var _g = React.useState([]), userQuestionError = _g[0], setUserQuestionError = _g[1];
    var _h = React.useState(false), loading = _h[0], setLoading = _h[1];
    var _j = React.useState(), postAnswerResponse = _j[0], setPostAnswerResponse = _j[1];
    var _k = React.useState(0), quizTotalPontos = _k[0], setQuizTotalPontos = _k[1];
    var history = useHistory();
    var showFlashMessage = useFlashMessage()[0];
    var isLastQuestion = currentQuestionIndex + 1 === ((_a = quiz === null || quiz === void 0 ? void 0 : quiz.perguntas) === null || _a === void 0 ? void 0 : _a.length);
    var _l = React.useState(''), campoAberto = _l[0], setCampoAberto = _l[1];
    // eslint-disable-next-line @typescript-eslint/no-shadow, react-hooks/exhaustive-deps
    var handleOnLearningBtnClick = function (quiz) {
        axiosClient.post("registro-ajuda/entrada/" + quiz.id);
        history.push({
            pathname: AppPath.Learning,
            state: { quiz: quiz, currentQuestionIndex: currentQuestionIndex, withTimeRegister: true, goToQuiz: true },
        });
    };
    useEffect(function () {
        axiosClient
            .get(COMUNICADO_URL + "/" + id)
            .then(function (response) {
            setQuiz(response.data.data);
            var quizData = response.data.data;
            if (!localStorage.hasOwnProperty("userSawHelp" + response.data.data.id) ||
                response.data.data.perguntas.length === 0) {
                return handleOnLearningBtnClick(response.data.data);
            }
            if (localStorage.getItem('userQuestionError') == null) {
                localStorage.setItem('userQuestionError', '0');
            }
            localStorage.removeItem("userSawHelp" + quizData.id);
        })
            .catch(function (error) {
            console.error(error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setQuiz, id]);
    useEffect(function () {
        setCurrentQuestion(quiz === null || quiz === void 0 ? void 0 : quiz.perguntas[currentQuestionIndex]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quiz, currentQuestionIndex]);
    useEffect(function () {
        axiosClient.post("/comunicado-empresa/registro-acesso-comunicado/" + id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        if (localStorage.getItem('userQuestionError') == null) {
            localStorage.setItem('userQuestionError', '0');
        }
    }, []);
    var hasNextQuestion = currentQuestionIndex < (quiz === null || quiz === void 0 ? void 0 : quiz.perguntas.length) - 1;
    var nextQuestion = function () {
        setLoading(true);
        setPostAnswerResponse(undefined);
        if (hasNextQuestion) {
            setLoading(false);
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setCurrentAlternativeId(0);
        }
        else {
            setLoading(false);
            setCurrentQuestionIndex(0);
        }
        if (isLastQuestion && !userQuestionError.length) {
            setLoading(false);
            history.push({
                pathname: AppPath.ComunicadoCompleted,
                state: { coins: quizTotalPontos },
            });
        }
        if (isLastQuestion && userQuestionError.length) {
            setLoading(false);
            history.push(AppPath.Communicate);
        }
    };
    var playSound = function (isRightAnswer) {
        if (isRightAnswer) {
            playRightAnswerSound();
        }
        else {
            playWrongAnswerSound();
        }
    };
    var handleBtnClick = function () {
        setCurrentQuestionIndex(0);
        history.go(0);
        // history.push({
        //   pathname: `${AppPath.QuizComunicado}/${id}`,
        // });
    };
    var handleAnswer = function () {
        setLoading(true);
        var payload = {
            comunicadoId: quiz.id,
            perguntaId: currentQuestion.id,
            resposta: currentAlternativeId,
        };
        axiosClient.put(COMUNICADO_URL, payload).then(function (response) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            if (((_a = response.data) === null || _a === void 0 ? void 0 : _a.succeeded) == true) {
                setQuizTotalPontos((_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.quizTotalPontos);
                if (!isMute) {
                    playSound((_e = (_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.acertou);
                }
                setPostAnswerResponse((_f = response === null || response === void 0 ? void 0 : response.data) === null || _f === void 0 ? void 0 : _f.data);
                if (!((_h = (_g = response.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.acertou))
                    setUserQuestionError(function (prevList) { return __spreadArrays(prevList, [1]); }); // mudar a lógica
                setLoading(false);
            }
        });
        if (isLastQuestion) {
            if (userQuestionError.length) {
                var newLocal = 'Você não teve êxito no entendimento desse comunicado. Tente novamente.';
                showFlashMessage(FlashMessageEnum.alert, newLocal);
            }
        }
        // const mockResponse: any = { data: { acertou: false, alternativaCorreta: 'Exemplo de resp correta' } };
        setLoading(false);
    };
    var marksValuesArray = [];
    for (var index = 0; index <= 10; index++) {
        marksValuesArray.push({ value: index, label: index.toString() });
    }
    var getFooterBtnText = function () {
        return postAnswerResponse ? (hasNextQuestion ? 'PRÓXIMA' : 'CONCLUIR') : 'CONFIRMAR';
    };
    var getFooterBtnBgColor = function () {
        return postAnswerResponse
            ? postAnswerResponse.acertou
                ? Color.RightAnswerGreenText
                : Color.WrongAnswerRedText
            : theme.corPrimaria;
    };
    var getFooterBgColor = function () {
        return postAnswerResponse
            ? postAnswerResponse.acertou
                ? Color.RightAnswerGreenBg
                : Color.WrongAnswerRedBg
            : 'inherit';
    };
    var handleOnFooterBtnClick = function () {
        if (postAnswerResponse) {
            setCampoAberto('');
            nextQuestion();
        }
        else {
            currentQuestion.tipoPergunta === 2 ? handleCampoAbertoAnswer() : handleAnswer();
        }
    };
    var handleCampoAbertoAnswer = function () {
        setLoading(true);
        var payload = {
            comunicadoId: quiz.id,
            perguntaId: currentQuestion.id,
            tipoPergunta: 2,
            resposta: campoAberto,
        };
        axiosClient.put(COMUNICADO_URL, payload).then(function (response) {
            var _a, _b, _c, _d, _e, _f;
            if (((_a = response.data) === null || _a === void 0 ? void 0 : _a.succeeded) == true) {
                if (!isMute) {
                    playSound((_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.acertou);
                }
                setPostAnswerResponse((_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.data);
                if (!((_f = (_e = response.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.acertou))
                    setUserQuestionError(function (prevList) { return __spreadArrays(prevList, [1]); }); // mudar a lógica
                setLoading(false);
            }
        });
        if (isLastQuestion) {
            if (userQuestionError.length) {
                var newLocal = 'Você não teve êxito no entendimento desse comunicado. Tente novamente.';
                showFlashMessage(FlashMessageEnum.alert, newLocal);
            }
        }
        // const mockResponse: any = { data: { acertou: false, alternativaCorreta: 'Exemplo de resp correta' } };
        setLoading(false);
    };
    var handleIfHasToBeDisabled = function () {
        if ((currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === 2) {
            return (campoAberto === null || campoAberto === void 0 ? void 0 : campoAberto.length) < 1;
        }
        else {
            return !currentAlternativeId;
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { primaryColor: true, className: "use-inverse-colors theme-main-background theme-background-gradient" },
            React.createElement(Header, { withBackButton: true, title: "Comunicados", backButtonFn: function () { return history.push(AppPath.Map); } },
                React.createElement(EntHeaderWrapper, { backgroundColor: "#FFEFE8" },
                    React.createElement("img", { src: handMessageSrc, alt: "figura dos comunicados" }),
                    React.createElement("span", null, 'Comunicados')))),
        React.createElement(StyledContainer, { withPadding: true, noPaddingBottom: true, flexRow: true, justifyEnd: true },
            React.createElement(Button, { onClick: function () { return handleOnLearningBtnClick(quiz); }, variant: "secondary" },
                React.createElement(BsFillPatchQuestionFill, null),
                " Ajuda")),
        React.createElement("div", null,
            React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter700, fontSize: '20px', color: '#49443C' }, variant: "h1", component: "div", textAlign: "center" },
                React.createElement(HTMLDisplayToQuestion, { htmlContent: currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.titulo })),
            React.createElement(Separator, null),
            (currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.tipoPergunta) === 2 ? (React.createElement("div", { style: { paddingInline: '24px' } },
                React.createElement(Textarea, { placeholder: "Descreva...", onChange: function (e) { return setCampoAberto(e.target.value); }, value: campoAberto, style: { width: '100%', minHeight: '274px' } }))) : (React.createElement("div", { style: { paddingInline: '24px' } },
                React.createElement(NewQuestionBodyComponent, { alternatives: (_b = currentQuestion === null || currentQuestion === void 0 ? void 0 : currentQuestion.alternativas) === null || _b === void 0 ? void 0 : _b.map(function (item) { return ({
                        id: item.id,
                        title: item.descricao,
                        order: item.ordem,
                    }); }), onSelectAlternative: function (questionId) {
                        setCurrentAlternativeId(questionId);
                    }, disabled: postAnswerResponse }))),
            React.createElement(Separator, null),
            React.createElement(Footer, { btnText: getFooterBtnText(), btnBgColor: getFooterBtnBgColor(), bgColor: getFooterBgColor(), handleOnBtnClick: handleOnFooterBtnClick, isLoading: loading, isBtnDisabled: handleIfHasToBeDisabled() }, postAnswerResponse && (React.createElement(AnswerResponseWrapper, { rightAnswer: postAnswerResponse },
                !isLastQuestion && postAnswerResponse.acertou && (React.createElement("div", { className: "tw-flex mb-4" },
                    React.createElement(FaIcon.Check, { style: { fontSize: '60px' } }),
                    React.createElement("div", null,
                        React.createElement(H4, { style: { paddingLeft: '20px' } }, randomMessage())))),
                isLastQuestion && Boolean(!userQuestionError.length) && (React.createElement("div", { className: "tw-flex mb-4" },
                    React.createElement(FaIcon.Check, { style: { fontSize: '60px' } }),
                    React.createElement("div", null,
                        React.createElement(H4, { style: { paddingLeft: '20px' } }, randomMessage())))),
                !isLastQuestion && !postAnswerResponse.acertou && (React.createElement("div", { className: "tw-flex mb-4" },
                    React.createElement(FaIcon.ErroX, { style: { fontSize: '60px', color: 'rgb(234, 43, 43)' } }),
                    React.createElement("div", null,
                        React.createElement(H4, { style: {
                                fontWeight: 'bold',
                                paddingLeft: '20px',
                                paddingBottom: '10px',
                                color: 'rgb(234, 43, 43)',
                            } }, "Resposta errada.")))),
                isLastQuestion && Boolean(userQuestionError.length) && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { expanded: true, onClick: handleBtnClick, loading: loading, disabled: !currentAlternativeId, style: {
                            backgroundColor: 'rgb(234, 43, 43)',
                            borderColor: 'rgb(234, 43, 43)',
                            opacity: 'inherit',
                        } }, "TENTAR NOVAMENTE")))))))));
};
