import React from 'react';
import { Apps } from '@mui/icons-material';
import { GiHamburgerMenu } from 'react-icons/all';
import { BsArrowLeftRight } from 'react-icons/bs';
import { HomeOption } from '@app/modules/map/map.page.style';
import { StepContainer, BoldParagraph, Paragraph, CentralizedRow } from '../tutorial.styles';
var QuartoStep = function () { return (React.createElement(StepContainer, null,
    React.createElement(BoldParagraph, null, "Como navegar na aplica\u00E7\u00E3o"),
    React.createElement(Paragraph, null, "Esse bot\u00E3o sempre estar\u00E1 presente no topo direito da P\u00E1gina Inicial."),
    React.createElement(CentralizedRow, null,
        React.createElement(HomeOption, null,
            React.createElement(Apps, { style: { color: 'white', fontSize: '23px' } })),
        React.createElement(Paragraph, { style: { marginTop: '5%' } }, "Nosso aplicativo normalmente \u00E9 apresentado como uma lista, mas voc\u00EA tem a op\u00E7\u00E3o de mudar a sua forma de apresenta\u00E7\u00E3o para serem visualizados como se fossem os aplicativos do seu celular. Ao clicar no bot\u00E3o ele mudar\u00E1 o layout do app e, tamb\u00E9m, o pr\u00F3prio layout do bot\u00E3o, o que possibilita fazer o caminho inverso sempre que necess\u00E1rio.")),
    React.createElement(CentralizedRow, null,
        React.createElement(HomeOption, null,
            React.createElement(Apps, { style: { color: 'white', fontSize: '23px' } })),
        React.createElement(BsArrowLeftRight, { color: "black" }),
        React.createElement(HomeOption, null,
            React.createElement(GiHamburgerMenu, { style: { color: 'white', fontSize: '23px' } }))))); };
export default QuartoStep;
