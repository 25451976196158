import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { BsFillPatchQuestionFill } from 'react-icons/bs';
import { ImgContainer } from '@app/components/mol.card/module-card.component.styled';
import { StepContainer, BoldParagraph, Paragraph, CentralizedRow, Title } from '../tutorial.styles';
var DecimoPrimeiroStep = function () { return (React.createElement(StepContainer, null,
    React.createElement(BoldParagraph, null, "Para auxiliar no entendimento"),
    React.createElement(CentralizedRow, null,
        React.createElement(ImgContainer, null,
            React.createElement(HelpOutlineIcon, { style: { color: '#C70000' } })),
        React.createElement(Paragraph, { style: { marginTop: '5%', marginBottom: '5%' } }, "Sempre que voc\u00EA encontrar esse s\u00EDmbolo acima, ao clicar nele, voc\u00EA ter\u00E1 acesso a uma descri\u00E7\u00E3o que vai te ajudar na compreens\u00E3o daquele \u00EDtem."),
        React.createElement(Title, { style: { fontSize: '1rem', marginBottom: '10px', paddingRight: '10px' } }, "Ajuda"),
        React.createElement(ImgContainer, null,
            React.createElement(BsFillPatchQuestionFill, { color: "#F4D300" })),
        React.createElement(Paragraph, { style: { marginTop: '5%', marginBottom: '5%' } }, "Sempre que voc\u00EA encontrar o s\u00EDmbolo de ajuda acima, quer dizer que existe alguma informa\u00E7\u00E3o importante para ser compartilhada que ir\u00E1 te ajudar na compreens\u00E3o do \u00EDtem ou atividade em quest\u00E3o. Ao clicar nele, voc\u00EA ser\u00E1 direcionado para essa informa\u00E7\u00E3o auxiliar.")))); };
export default DecimoPrimeiroStep;
