import React from 'react';
import { BsEnvelopeExclamation } from 'react-icons/all';
import { ImgContainer } from '@app/components/mol.card/module-card.component.styled';
import { StepContainer, BoldParagraph, Paragraph, CentralizedRow, BoldWord, Title } from '../tutorial.styles';
var SetimoStep = function () { return (React.createElement(StepContainer, null,
    React.createElement(BoldParagraph, { style: { marginBottom: '2%' } }, "Nossas funcionalidades"),
    React.createElement(CentralizedRow, null,
        React.createElement(ImgContainer, null,
            React.createElement(BsEnvelopeExclamation, null)),
        React.createElement(Title, { style: { fontSize: '1rem', marginBottom: '10px', paddingLeft: '10px' } }, "Comunicados"),
        React.createElement(Paragraph, { style: { marginTop: '2%' } }, "Aqui voc\u00EA ir\u00E1 visualizar os comunicados lan\u00E7ados pela sua empresa."),
        React.createElement(Paragraph, { style: { marginTop: '2%' } }, "Normalmente o comunicado requer uma resposta simples, por isso, lembre-se sempre de abrir cada comunicado."),
        React.createElement(Paragraph, { style: { marginTop: '2%' } },
            "Fique atento aos status dos comunicados, existem os comunicados ",
            React.createElement(BoldWord, null, "novos"),
            ", os",
            ' ',
            React.createElement(BoldWord, null, "n\u00E3o lidos"),
            " (quando ele j\u00E1 deixou de ser novo e voc\u00EA ainda nao o abriu), os",
            ' ',
            React.createElement(BoldWord, null, "pendentes"),
            " (quando o comunicado n\u00E3o for lido ou respondido dentro do seu prazo) e os",
            ' ',
            React.createElement(BoldWord, null, "conclu\u00EDdos"),
            " (quando voc\u00EA abre e responde qualquer comunicado).")))); };
export default SetimoStep;
