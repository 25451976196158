var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import styled from 'styled-components';
import { HomeOption } from '@app/modules/map/map.page.style';
import { StepContainer, BoldParagraph, Paragraph, CentralizedRow } from '../tutorial.styles';
// Styled container for the notification icon
var NotificationContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative; /* Make the container a relative positioned element */\n  display: inline-block; /* Make sure the container only takes the space it needs */\n"], ["\n  position: relative; /* Make the container a relative positioned element */\n  display: inline-block; /* Make sure the container only takes the space it needs */\n"])));
// Styled red dot
var RedDot = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute; /* Position the dot absolutely within the container */\n  top: 0; /* Align to the top */\n  right: 0; /* Align to the right */\n  width: 8px; /* Size of the dot */\n  height: 8px; /* Size of the dot */\n  background-color: red; /* Red color for the dot */\n  border-radius: 50%; /* Make it round */\n  border: 1px solid white; /* Optional: border to make it stand out better */\n"], ["\n  position: absolute; /* Position the dot absolutely within the container */\n  top: 0; /* Align to the top */\n  right: 0; /* Align to the right */\n  width: 8px; /* Size of the dot */\n  height: 8px; /* Size of the dot */\n  background-color: red; /* Red color for the dot */\n  border-radius: 50%; /* Make it round */\n  border: 1px solid white; /* Optional: border to make it stand out better */\n"])));
var SextoStep = function () { return (React.createElement(StepContainer, null,
    React.createElement(BoldParagraph, null, "Como navegar na aplica\u00E7\u00E3o"),
    React.createElement(Paragraph, { style: { marginTop: '5%', marginBottom: '5%' } }, "Esse bot\u00E3o representa as \u201CNotifica\u00E7\u00F5es\u201D, ele sempre estar\u00E1 presente no topo direito de todas as p\u00E1ginas."),
    React.createElement(CentralizedRow, null,
        React.createElement(HomeOption, null,
            React.createElement(NotificationsNoneIcon, { style: { color: 'white', fontSize: '23px' } })),
        React.createElement(Paragraph, { style: { marginTop: '5%', marginBottom: '5%' } }, "Sempre que existirem notifica\u00E7\u00F5es que ainda n\u00E3o foram lidas, o comportamento do bot\u00E3oser\u00E1 da seguinte forma:")),
    React.createElement(CentralizedRow, null,
        React.createElement(HomeOption, null,
            React.createElement(NotificationContainer, null,
                React.createElement(NotificationsNoneIcon, { style: { color: 'white', fontSize: '23px' } }),
                React.createElement(RedDot, null))),
        React.createElement(Paragraph, { style: { marginTop: '5%', marginBottom: '5%' } }, "Ao clicar no bot\u00E3o, voc\u00EA ter\u00E1 acesso a lista de todas as notifica\u00E7\u00F5es direcionadas para voc\u00EA, sejam elas referentes aos Comunicados, Pesquisas, Chechlists, Caminhos do Conhecimento ou de qualquer outra atividade.")))); };
// You can add more step components here following the same pattern...
export default SextoStep;
var templateObject_1, templateObject_2;
